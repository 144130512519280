import React, { useEffect, useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker, Polyline, InfoWindow } from "@react-google-maps/api";
import customMarkerImage from "../../assets/images/icons/technology/react.png";

const containerStyle = {
  height: "400px",
};

function MapView({ apiKey, coordinates = [], lines = true }) {
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(16); // Set initial zoom level to 16
  const [markersState, setMarkersState] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null); // State to track the selected marker
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (coordinates?.length > 0) {
      const avgLat =
        coordinates.reduce((acc, marker) => acc + marker.lat, 0) /
        coordinates?.length;
      const avgLng =
        coordinates.reduce((acc, marker) => acc + marker.lng, 0) /
        coordinates?.length;
      setCenter({ lat: avgLat, lng: avgLng });
      setMarkersState(coordinates);
    }
  }, [coordinates]);

  useEffect(() => {
    if (markersState?.length > 0) {
      setCenter({ lat: markersState[0].lat, lng: markersState[0].lng });
      setZoom(14); // Zoom level set to 16
    }
  }, [markersState]);

  if (loadError) {
    console.error("Error loading Google Maps API:", loadError);
    return <div>Error loading map</div>;
  }

  return isLoaded && center ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {coordinates?.length !== 0 &&
        markersState?.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            icon={{
              url: marker.icon?.default || customMarkerImage,
              scaledSize: new window.google.maps.Size(40, 40), // Set the size of the marker (width, height)
              anchor: new window.google.maps.Point(20, 20), // Adjust the anchor point if necessary (optional)
            }}
            onClick={() => setSelectedMarker(marker)} // Set selected marker on click
          />
        ))}
      {selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={() => setSelectedMarker(null)} // Close info window
        >
          <div>
            <h3>{selectedMarker.messageTitle || `Marker ${selectedMarker.lat}, ${selectedMarker.lng}`}</h3>
            <p>{selectedMarker.messageDescription || "No additional information."}</p>
          </div>
        </InfoWindow>
      )}

      {lines &&
        <Polyline
          path={markersState.map(marker => ({ lat: marker.lat, lng: marker.lng }))}
          options={{
            strokeColor: markersState.some(marker => marker?.type === "sale") ? "#0E72AD" : '#84DFAD',
            strokeOpacity: 1.0, // Set opacity between 0.0 and 1.0
            strokeWeight: 5,
          }}
        />
      }
    </GoogleMap>
  ) : (
    <div>Loading map...</div>
  );
}

export default MapView;
