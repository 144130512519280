import { Fragment, useEffect, useState } from "react";
import Avatar from "@components/avatar";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Bell, X, Check, AlertTriangle } from "react-feather";
import {
  Button,
  Badge,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { SC } from "../../../../services/Api/serverCall";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const baseUrl = process.env.REACT_APP_BASEURL;

const NotificationDropdown = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsArray, setNotificationsArray] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const notificationsPerPage = 200;
  const totalPages = Math.ceil(
    notificationsArray?.length / notificationsPerPage
  );
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData"));


  useEffect(() => {
    getNotifications(currentPage, notificationsPerPage);
  }, [currentPage]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const getNotifications = (page, size) => {
    let token = JSON.parse(localStorage.getItem("accessToken"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      page: 0,
      size: 500,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${baseUrl}portal/C01PortalGetUserNotifications`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result);
        setNotificationsArray(response?.DATA);
      })
      .catch((error) => console.error(error));
  };

  const onReadNotification = (item) => {
    console.log("=--item", item);
    let token = JSON.parse(localStorage.getItem("accessToken"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      id: item?.notificationId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${baseUrl}common/c01ReadNotifications`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result);
        console.log("=-=-response", response);
        if (response?.CODE === 1) {
          getNotifications()
          navigate(item?.slug);
          setDropdownOpen(false); // Close the dropdown after navigating
        }
      })
      .catch((error) => console.error(error));
  };

  const renderNotificationItems = () => {
    const startIndex = (currentPage - 1) * notificationsPerPage;
    const currentNotifications = notificationsArray?.slice(
      startIndex,
      startIndex + notificationsPerPage
    );

    return (
      <PerfectScrollbar
        component="li"
        className="media-list scrollable-container"
        options={{
          wheelPropagation: false,
        }}
        style={{ maxHeight: "500px" }}
      >
        {currentNotifications?.map((item, index) => (
          <a
            key={index}
            className="d-flex"
            href="#"
            onClick={(e) => e.preventDefault()}
          >
            <div
              className={classnames("list-item d-flex", {
                "align-items-start": true,
              })}
            >
              <Fragment>
                <div
                  onClick={() => onReadNotification(item)}
                  className="list-item-body flex-grow-1"
                >
                  <p className="media-heading">
                    <span
                      className="fw-bolder"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {item?.isRead === 0 && (
                        <div
                          style={{
                            backgroundColor: "#0E72AD",
                            height: 10,
                            width: 10,
                            borderRadius: 100 / 2,
                            marginRight: 10,
                          }}
                        />
                      )}
                      <div>{item.notificationTitle}</div>
                    </span>
                  </p>
                  <small className="notification-text">
                    {item.notificationMessage}
                  </small>
                </div>
              </Fragment>
            </div>
          </a>
        ))}
      </PerfectScrollbar>
    );
  };

  return (
    <UncontrolledDropdown
      tag="li"
      className="dropdown-notification nav-item me-25 mr-2"
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle
        tag="a"
        className="nav-link"
        href="/"
        onClick={(e) => e.preventDefault()}
      >
        <Bell size={25} />
        <Badge pill color="primary" className="badge-up">
          {
            notificationsArray.filter(
              (notification) => notification.isRead === 0
            ).length
          }
        </Badge>
      </DropdownToggle>
      <DropdownMenu
        end
        tag="ul"
        className="dropdown-menu-media mt-0"
      // style={{ maxHeight: "300px", overflowY: "auto" }}
      >
        <li className="dropdown-menu-header">
          <DropdownItem className="d-flex" tag="div" header>
            <h4 className="notification-title mb-0 me-auto">Notifications</h4>
          </DropdownItem>
        </li>
        {renderNotificationItems()}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default NotificationDropdown;
