import { lazy } from "react";
import WorkFlowDetail from "../../views/pages/WorkFlow/Details";
const Features = lazy(() => import("../../views/pages/FormList"));
const Dashboard = lazy(() => import("../../views/pages/dashboard"));
const WorkFlow = lazy(() => import("../../views/pages/WorkFlow"));
const R116 = lazy(() => import("../../views/pages/Reports/R116"));
const R296 = lazy(() => import("../../views/pages/Reports/R296"));
const R782 = lazy(() => import("../../views/pages/Reports/R782/index"));
const R787 = lazy(() => import("../../views/pages/Reports/R787/index"));
const R791 = lazy(() => import("../../views/pages/Reports/R791/index"));
const R792 = lazy(() => import("../../views/pages/Reports/R792/index"));
const R794 = lazy(() => import("../../views/pages/Reports/R794/index"));
const R120 = lazy(() => import("../../views/pages/Reports/R120/index"));
const R238 = lazy(() => import("../../views/pages/Reports/R238/index"));
const R799 = lazy(() => import("../../views/pages/Reports/R799/index"));
const R810 = lazy(() => import("../../views/pages/Reports/R810/index"));
const R811 = lazy(() => import("../../views/pages/Reports/R811/index"));
const R815 = lazy(() => import("../../views/pages/Reports/R815/index"));
const Feature793 = lazy(() => import("../../views/pages/features/Feature793"));
const Feature427 = lazy(() => import("../../views/pages/features/Feature427"));
const Feature374 = lazy(() => import("../../views/pages/features/Feature374"));
const Feature423 = lazy(() => import("../../views/pages/features/Feature423"));
const Feature192 = lazy(() => import("../../views/pages/features/Feature192"));
const Feature82 = lazy(() =>
  import("../../views/pages/features/Feature82/index")
);
const Feature788 = lazy(() =>
  import("../../views/pages/features/Feature788/index")
);
const Feature808 = lazy(() =>
  import("../../views/pages/features/Feature808/index")
);
const Feature804 = lazy(() =>
  import("../../views/pages/features/Feature804/index")
);
const Feature52 = lazy(() =>
  import("../../views/pages/features/Feature52/index")
);
const Feature805 = lazy(() =>
  import("../../views/pages/features/Feature805/index")
);
const DispatchDelivery = lazy(() =>
  import("../../views/pages/AllForms/DispatchDelivery")
);
const DispatchBooking = lazy(() =>
  import("../../views/pages/AllForms/DispatchBooking")
);
const DispatchMerchandising = lazy(() =>
  import("../../views/pages/AllForms/DispatchMerchandising")
);
const ClosedOutlets = lazy(() =>
  import("../../views/pages/AllForms/ClosedOutlets")
);
const AddPjps = lazy(() =>
  import("../../views/pages/features/Feature82/AddPjps")
);
const EditPjps = lazy(() =>
  import("../../views/pages/features/Feature82/EditPjps")
);
const OrderNow = lazy(() =>
  import("../../views/pages/features/Feature52/OrderNow/index")
);

const ListRoutes = [
  {
    element: <Features />,
    path: "/",
    route: "dashboard",
    slug: "dashboard",
    title: "dashboard",
  },
  {
    element: <Features />,
    path: "/featuesList",
    route: "featuesList",
    slug: "featuesList",
    title: "featuesList",
  },
  {
    element: <R116 />,
    path: "/R116",
    route: "R116",
    slug: "R116",
    title: "R116",
  },
  {
    element: <R296 />,
    path: "/R296",
    route: "R296",
    slug: "R296",
    title: "R296",
  },
  {
    element: <R116 />,
    path: "/R116",
    route: "R116",
    slug: "R116",
    title: "R116",
  },
  {
    element: <R782 />,
    path: "/R782",
    route: "R782",
    slug: "R782",
    title: "R782",
  },
  {
    element: <R787 />,
    path: "/R787",
    route: "R787",
    slug: "R787",
    title: "R787",
  },
  {
    element: <R799 />,
    path: "/R799",
    route: "R799",
    slug: "R799",
    title: "R799",
  },
  {
    element: <R810 />,
    path: "/R810",
    route: "R810",
    slug: "R810",
    title: "R810",
  },
  {
    element: <R811 />,
    path: "/R811",
    route: "R811",
    slug: "R811",
    title: "R811",
  },
  {
    element: <R815 />,
    path: "/R815",
    route: "R815",
    slug: "R815",
    title: "R815",
  },
  {
    element: <R791 />,
    path: "/R791",
    route: "R791",
    slug: "R791",
    title: "R791",
  },
  {
    element: <R238 />,
    path: "/R238",
    route: "R238",
    slug: "R238",
    title: "R238",
  },
  {
    element: <R120 />,
    path: "/R120",
    route: "R120",
    slug: "R120",
    title: "R120",
  },
  {
    element: <R792 />,
    path: "/R792",
    route: "R792",
    slug: "R792",
    title: "R792",
  },
  {
    element: <R794 />,
    path: "/R794",
    route: "R794",
    slug: "R794",
    title: "R794",
  },
  {
    element: <Feature793 />,
    path: "/Feature793",
    route: "Feature793",
    slug: "Feature793",
    title: "Feature793",
  },
  {
    element: <Feature427 />,
    path: "/Feature427",
    route: "Feature427",
    slug: "Feature427",
    title: "Feature427",
  },
  {
    element: <Feature374 />,
    path: "/Feature374",
    route: "Feature374",
    slug: "Feature374",
    title: "Feature374",
  },
  {
    element: <Feature192 />,
    path: "/Feature192",
    route: "Feature192",
    slug: "Feature192",
    title: "Feature192",
  },
  {
    element: <Feature808 />,
    path: "/Feature808",
    route: "Feature808",
    slug: "Feature808",
    title: "Feature808",
  },
  {
    element: <Feature82 />,
    path: "/Feature82",
    route: "Feature82",
    slug: "Feature82",
    title: "Feature82",
  },
  {
    element: <Feature788 />,
    path: "/Feature788",
    route: "Feature788",
    slug: "Feature788",
    title: "Feature788",
  },
  {
    element: <Feature805 />,
    path: "/Feature805",
    route: "Feature805",
    slug: "Feature805",
    title: "Feature805",
  },
  {
    element: <Feature804 />,
    path: "/Feature804",
    route: "Feature804",
    slug: "Feature804",
    title: "Feature804",
  },
  {
    element: <Feature52 />,
    path: "/Feature52",
    route: "Feature52",
    slug: "Feature52",
    title: "Feature52",
  },
  {
    element: <Feature423 />,
    path: "/Feature423",
    route: "Feature423",
    slug: "Feature423",
    title: "Feature423",
  },
  //
  {
    element: <AddPjps />,
    path: "/AddPjps",
    route: "AddPjps",
    slug: "AddPjps",
    title: "AddPjps",
  },
  {
    element: <EditPjps />,
    path: "/EditPjps",
    route: "EditPjps",
    slug: "EditPjps",
    title: "EditPjps",
  },
  {
    element: <OrderNow />,
    path: "/OrderNow",
    route: "OrderNow",
    slug: "OrderNow",
    title: "OrderNow",
  },

  {
    element: <WorkFlow />,
    path: "/WorkFlow",
    route: "WorkFlow",
    slug: "WorkFlow",
    title: "WorkFlow",
  },
  {
    element: <WorkFlowDetail />,
    path: "/WorkFlow/WorkFlowDetail",
    route: "WorkFlow/WorkFlowDetail",
    slug: "WorkFlow/WorkFlowDetail",
    title: "WorkFlow/WorkFlowDetail",
  },

  // FORMS
  {
    element: <DispatchDelivery />,
    path: "/dispatct-delivery",
    route: "dispatct-delivery",
    slug: "dispatct-delivery",
    title: "dispatct-delivery",
  },
  {
    element: <DispatchBooking />,
    path: "/dispatct-booking",
    route: "dispatct-booking",
    slug: "dispatct-booking",
    title: "dispatct-booking",
  },
  {
    element: <DispatchMerchandising />,
    path: "/dispatct-merchandising",
    route: "dispatct-merchandising",
    slug: "dispatct-merchandising",
    title: "dispatct-merchandising",
  },
  {
    element: <ClosedOutlets />,
    path: "/closed-outlets",
    route: "closed-outlets",
    slug: "closed-outlets",
    title: "closed-outlets",
  },
];
export default ListRoutes;
